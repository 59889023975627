

/* Actual CSS */
.placeholder{
    height: 100vh;
}

#threemap-fullscreen-container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    transition: all 1.5s;
    clip-path: inset(10% round 1em);
}

#threemap-fullscreen-container.fullscreen {
    position: relative;
    clip-path: inset(0% round 0);
    z-index: 1050;
    touch-action: none;
}

.canvas-container-map {
    position: relative;
    height: 100%;
    width: 100%;
}


#three-canvas-map {
    height: 100% !important;
    width: 100% !important;
    cursor: grab;
}

#three-canvas-map:active {
    cursor: grabbing;
}

/** Points **/

.pointWrapper{
    position: absolute;
    top:50%;
    left: 50%;
    display: none;
    cursor: pointer;
}

.pointWrapper.visible {
    display: block;
}

.center{
    position: relative;
    width: 7em;
    height: 6em;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.point {
    position: absolute;
    width: 1.4em;
    background-color: #f7ef949e;
    border-radius: 999px;
    aspect-ratio: 1;
    transition: all 0.3s;
    z-index: 10;
}

.center:hover .point {
    background-color: #f7ef94e6;
    transform: scale(1.3) translate(0, 20%);
}



.label{
    position: absolute;
    opacity: 0;
    padding: 0.3em;
    background-color: #fff;
    color: #011122;
    border-radius: 4px;
    transition: all 0.3s;
    min-width: max-content;
    transform: translate(0,40%);
}

.center:hover .label {
    opacity: 1;
    transform: translate(0,-90%);
}

/** Cards **/

.close {
    position: absolute;
    top: 2em;
    right: 2em;
    border-radius: 999px;
    background-color: #011122ae;
    aspect-ratio: 1;
    width: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
}

.close > svg {
    color: #F7EF94;
    height: 1.5em;
    width: 1.5em;
}

.close:hover {
    background-color: #2f3d4bae;
}

.three-cards {
    top: 0;
    left: 0;
}

.three-cards .three-card{
    position: absolute;

}

.three-card{
    background-color: #011122;
    max-width: 20em;
    padding: 2em;
    color: white;
    border: #F7EF94 10px solid;
    display: none;
    opacity: 0;
    top: 50vh;
    transform: translate(0, -50%);
    touch-action: none;
}

.three-card.right{
    right: 7vw
}

.three-card.left{
    left: 7vw
}

.three-card > h2{
    color: #F7EF94;
}

/* Responsivness */
@media screen and (max-width:992px) {
    .three-card{
        transform: none;
        top: unset;
        bottom: 7vw;
    }
}

@media screen and (max-width:762px) {
    .three-card.left,
    .three-card.right{
        left: 5vw;
        right: 5vw;
        bottom: 5vw;
        width: 100%;
        max-width: fit-content;
        max-width: intrinsic;
    }
}